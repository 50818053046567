import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import { resetPasswordFormSchema } from "config/schemas/formSchema";
import { encryptData } from "utilityFunction/encryption&Decryption";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment, InputLabel, Modal } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import mtsLogo from "assets/images/mts-logo.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { jwtDecode } from "jwt-decode";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";

function Copyright(props) {
  return (
    <Typography
      variant="caption"
      align="center"
      className="footer-text"
      {...props}
    >
      {"Copyright © "}{new Date().getFullYear()}{" "}
      <Link
        className="text my-true-skill"
        to="https://mytrueskills.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        My True Skills
      </Link>{" "}
    </Typography>
  );
}

export default function ResetPassword() {
  const navigate = useNavigate();
  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);

  //state to store the form submission state
  const [formSubmitted, setFormSubmitted] = useState(false);

  //state to store the password
  const [showPassword, setShowPassword] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  //store the token in a var
  const { token } = useParams();
  console.log(token)
  // decode using jwt.verify() pass token and secretky here, console.log the value you get from token you get expie tme , check if token time is excedding current presetnt time if yes then show the modal 

  const [decodedToken, setDecodedToken] = useState(null);

  useEffect(() => {
    if (token) {
      try {
        const decoded = jwtDecode(token); // Decode the token (no verification)
        console.log("Decoded Token:", decoded);
        setDecodedToken(decoded);

        // Check for expiration
        const expirationDate = decoded.exp * 1000; // Convert expiration to milliseconds
        if (Date.now() >= expirationDate) {
          console.log("Token expired");
          setTokenExpired(true);
          setOpenModal(true)
        }
      } catch (error) {
        console.error("Token decoding failed:", error);
      }
    }
  }, [token]);
  // Improved token validation and error handling
  useEffect(() => {
    if (!token) {
      notifyUser(
        "Authentication Error",
        "Reset token is missing or invalid",
        "warning"
      );
      navigate("/login");
    }
  }, [token, navigate, notifyUser]);

  const handleSubmitForm = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      notifyUser(
        "Validation Error",
        "New password and confirm password do not match",
        "error"
      );
      return;
    }

    try {
      // Encrypt the new password
      const encryptedNewPassword = encryptData(values.newPassword);
      const response = await axios.post(
        `${API_ENDPOINTS.RESET_AUTH_PASSWORD}`,
        {
          password_hash: encryptedNewPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass token in the Authorization header
          },
        }
      );

      setFormSubmitted(true);
      notifyUser(
        "Password Reset Successful",
        "Your password has been reset successfully",
        "success"
      );
      navigate("/login");
    } catch (error) {
      console.error("Error in password reset:", error);
      notifyUser(
        "Password Reset Failed",
        error.response?.data?.message || "An unexpected error occurred",
        "error"
      );
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: resetPasswordFormSchema,
    onSubmit: handleSubmitForm,
  });

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    // navigate("/login"); // Redirect to login page after modal close
  };

  return (
    <Box className="auth-form-wrapper">
      {tokenExpired && (
        //   <div 
        //   className="auth-layout__container" 
        //   style={{
        //     position: 'fixed',
        //     top: '50%',
        //     left: '50%',
        //     transform: 'translate(-50%, -50%)',
        //     display: 'flex',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     width: '100vw',
        //     height: '100vh',
        //     overflow: 'hidden',
        //     margin: 0,
        //     padding: 0,
        //   }}
        // >
        //   <Modal
        //   open={openModal}
        //   onClose={handleCloseModal}
        //   aria-labelledby="expired-token-modal"
        //   aria-describedby="expired-token-description"
        // >
        //   <Box className="forgot-pwd__form-card">
        //     <Box className="forget-pwd_form-wrapper">
        //       <img src={mtsLogo} alt="logo" className="forgot-pwd__card-logo" />
        //       <Typography
        //         component="h1"
        //         variant="h5"
        //         className=" text forgot-pwd__header-text"
        //       >
        //         Token Expired
        //       </Typography>
        //       <Typography variant="h6" className="text forgot-pwd__header-caption">
        //         Your reset token has expired. Please go back to the login page and request a new token.
        //       </Typography>
        //       <Box mt={2} className="forgot-pwd_form">
        //         <Button
        //           variant="contained"
        //           onClick={handleCloseModal}
        //           fullWidth
        //           className="button button-submit"
        //         >
        //           Go to Login
        //         </Button>
        //       </Box>
        //     </Box>
        //   </Box>
        // </Modal>
        // </div>
        <div></div>

      )}
      <Box className="forgot-pwd__form-card" id="reset-password">
        <Box className="forget-pwd_form-wrapper">

          {!tokenExpired ? (
            <img src={mtsLogo} alt="logo" className="forgot-pwd__card-logo" />
          ) : (
            <img src={mtsLogo} alt="logo" />
          )}
          {!tokenExpired ? (
            <Typography
              component="h1"
              variant="h5"
              className=" text forgot-pwd__header-text"
            >
              Reset Password
            </Typography>
          ) : (
            <Typography
              component="h1"
              variant="h5"
              className=" text forgot-pwd__header-text"
            >
              Link expired
            </Typography>
          )}
          {/* <Typography
            component="h1"
            variant="h5"
            className=" text forgot-pwd__header-text"
          >
            Reset Password
          </Typography> */}

          {!tokenExpired ? (
            <Typography variant="h6" className="text forgot-pwd__header-caption">
              To reset your password, Enter new password
            </Typography>
          ) : (
            <>
              <Typography variant="h6" className="text forgot-pwd__header-caption">
                Your reset link has expired. Please click the button below to generate a new link and regain access.
              </Typography>
              <Button
                variant="outlined"
                onClick={() => navigate("/forgot-password")}
                sx={{
                  textTransform: "none",
                  fontFamily: "Gilroy-Bold, sans-serif",
                  fontSize: "0.85rem",
                  padding: "0.7vh 2.5vh",
                  borderRadius: "30px",
                  color: "#3b5269",
                  borderColor: "#2b3d4f",
                  "&:hover": {
                    backgroundColor: "#39cccc",
                    color:'#ffffff',
                    borderColor: "#2b3d4f",
                  },
                }}
              >
                Request New Link
              </Button>
            </>
          )}

          {!tokenExpired && (
            <Box
              mt={2}
              component="form"
              className="forgot-pwd_form"
              noValidate
              onSubmit={formik.handleSubmit}
            >
              <Grid container mb={2}>
                <Grid item xs={12}>
                  <InputLabel className=" text input-field-label forgot-pwd__input-label forgot-pwd__input-label">
                    New Password
                  </InputLabel>
                  <TextField
                    type={showPassword ? "text" : "password"}
                    size="small"
                    className="input-field"
                    required
                    fullWidth
                    placeholder="********"
                    id="newPassword"
                    name="newPassword"
                    autoComplete="newPassword"
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOff sx={{ mr: 1, width: "60%" }} />
                            ) : (
                              <Visibility sx={{ mr: 1, width: "60%" }} />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {formik.errors.newPassword && formik.touched.newPassword ? (
                    <Typography className="error-input_login">
                      {formik.errors.newPassword}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <InputLabel className=" text input-field-label forgot-pwd__input-label--confirm-password">
                    Confirm Password
                  </InputLabel>
                  <TextField
                    type="password"
                    placeholder="********"
                    size="small"
                    className="input-field"
                    required
                    fullWidth
                    id="confirmPassword"
                    name="confirmPassword"
                    autoComplete="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                      },
                    }}
                  />
                  {formik.errors.confirmPassword &&
                    formik.touched.confirmPassword ? (
                    <Typography className="error-input_login">
                      {formik.errors.confirmPassword}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Button
                disabled={formik.isSubmitting}
                type="submit"
                fullWidth
                variant="contained"
                className="button button-submit"
                id="reset-password-submit"
              >
                Submit
              </Button>
              <Grid container justifyContent="center" mt={4} mb={2}>
                <Grid item>
                  <Typography className="text forgot-password">
                    <Link to="/login">
                      <NavigateBeforeIcon className="forgot-password__icon" />
                    </Link>
                    Back to
                    <Link to="/login" id="login">
                      {" "}
                      Login
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
}
