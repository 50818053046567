import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { forgotPasswordFormSchema } from "config/schemas/formSchema";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { InputLabel } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import mtsLogo from "assets/images/mts-logo.png";
import usePostRequest from "hooks/usePostRequest";
import { API_ENDPOINTS } from "config/ApiConfig";
import { encryptData } from "utilityFunction/encryption&Decryption";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";

function Copyright(props) {
  const currentYear = new Date().getFullYear();

  return (
    <Typography
      variant="caption"
      align="center"
      className="footer-text"
      {...props}
    >
      {`Copyright ©${currentYear} `}
      <Link
        className="text my-true-skill"
        to="https://mytrueskills.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        My True Skills
      </Link>
    </Typography>
  );
}

export default function ForgetPwd() {
  const { notifyUser } = useContext(NofityUserContext);

  const {
    data: forgotPasswordData,
    loading: loadingforgotPassword,
    error: forgotPasswordError,
    postData: forgotPasswordPayload,
  } = usePostRequest(API_ENDPOINTS.FORGOT_PASSWORD);

  const onSubmit = async (values, actions) => {
    try {
      // Convert email to lowercase before encryption and sending to backend
      const normalizedUsername = values.username.trim().toLowerCase();
      const emailOrUsername = encryptData(normalizedUsername);
      await forgotPasswordPayload({ emailOrUsername });
    } catch (err) {
      if (err.response) {
        notifyUser(
          "Login Failed",
          err.response.data.message || "An error occurred.",
          "error"
        );
      } else if (err.request) {
        notifyUser(
          "Network Error",
          "No response from the server. Please try again.",
          "warning"
        );
      } else {
        notifyUser("Unexpected Error", err.message, "error");
      }
    }

    if (!loadingforgotPassword) {
      actions.resetForm();
    }
  };

  useEffect(() => {
    if (!loadingforgotPassword) {
      if (forgotPasswordError) {
        notifyUser(
          "Password Reset Failed",
          `${forgotPasswordError.message}`,
          "error"
        );
      } else if (forgotPasswordData) {
        notifyUser(
          "Password Reset Email Sent",
          "We've sent you an email with instructions to reset your password",
          "success"
        );
      }
    }
  }, [forgotPasswordData, forgotPasswordError ] );

  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: forgotPasswordFormSchema,
    onSubmit,
  });

  return (
    <Box className="auth-form-wrapper">
      <Box className="forgot-pwd__form-card">
        <Box className="forget-pwd_form-wrapper">
          <img src={mtsLogo} alt="logo" className="forgot-pwd__card-logo" />
          <Typography
            component="h1"
            variant="h5"
            className=" text forgot-pwd__header-text"
          >
            Forgot Password
          </Typography>
          <Typography variant="h6" className="text forgot-pwd__header-caption">
            To reset your password, Enter your username or email
          </Typography>
          <Box
            mt={2}
            component="form"
            className="forgot-pwd_form"
            noValidate
            onSubmit={handleSubmit}
          >
            <Grid container mb={2}>
              <Grid item xs={12}>
                <InputLabel className=" text input-field-label forgot-pwd__input-label">
                  Email/Username
                </InputLabel>
                <TextField
                  placeholder="Eg : VF4D6TG4R"
                  autoFocus
                  size="small"
                  className="input-field"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  autoComplete="username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.username && touched.username ? (
                  <Typography className="error-input_login">
                    {errors.username}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Button
              disabled={isSubmitting}
              type="submit"
              fullWidth
              variant="contained"
              className="button button-submit"
            >
              Submit
            </Button>
            <Grid container justifyContent="center" mt={4} mb={2}>
              <Grid item>
                <Typography className="text forgot-password">
                  <Link to="/login">
                    <NavigateBeforeIcon className="forgot-password__icon" />
                  </Link>
                  <Link to="/login" id="login">
                    {" "}
                    Back to Login
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
}