import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import LessonContext from "context/lessonContext/LessonContext";
import { API_ENDPOINTS, BASE_URL, PRODUCTION_URL } from "config/ApiConfig";
import { useNavigate, useParams } from "react-router-dom";
import usePostRequest from "hooks/usePostRequest";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import QuizFeedback from "./QuizFeedback";
import QuizTimer from "./TimerCard";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizResultCard from "./QuizResultCard";

const QuestionAnswer = ({ content, onQuizSubmit }) => {
  const { message, notifyUser, clearUserNotification } =
    useContext(NofityUserContext);
  const [text, setText] = useState("");
  const [isTextEntered, setIsTextEntered] = useState(false); // New state for tracking text entry
  const { lessonInfo, sortedLessonData, lessonIdentifiers, handleDisableFullScreen } =
    useContext(LessonContext);
  const [quizData, setQuizData] = useState(null);
  const [thumbnailURL, setThumbnailURL] = useState("");
  const [minWords, setMinWords] = useState(0);
  const [maxWords, setMaxWords] = useState(200); // default value
  const [nextLessonSlug, setNextLessonSlug] = useState("");
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [contentDetails, setContentDetails] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateLessonCompletionData } = useContext(LessonContext);

  const isProduction = BASE_URL === PRODUCTION_URL;

  useEffect(() => {
    setTimerKey(timerKey + 1); // Reset timer when quiz starts
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(
        (item) => item.content_id === content
      );

      if (foundContent) {
        setContentDetails(foundContent);
      } else {
        // console.log("Content not found for the provided content_id:", content);
      }
    } else {
      // console.log(
      //   "lessonInfo is not available or does not contain content_id."
      // );
    }
  }, [lessonInfo, content]);

  const { data: saveQuixResponseData, postData: saveQuixResponse } =
    usePostRequest(API_ENDPOINTS.SAVE_QUIZ_PROGRESS);

  const { data: quizStatResponse, postData: quizStatPayload } = usePostRequest(
    API_ENDPOINTS.FETCH_QUIZ_STAT
  );

  const { lesson: currentLessonSlug, chapter: currentChapterSlug } =
    useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (contentDetails && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(
        contentDetails.RefContentType.name,
        contentDetails.contentDetails.quiz_sa_id,
        lessonIdentifiers?.course_id
      );
      setQuizStatData(true);
    }
  }, [contentDetails, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (sortedLessonData) {
      calculateNextLessonSlug(sortedLessonData);
    }
  }, [sortedLessonData, currentLessonSlug]);

  useEffect(() => {
    if (quizStatResponse && contentDetails) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (
        !quizStatResponse.quizfeedBackStat ||
        quizStatResponse.quizfeedBackStat.length === 0
      ) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (
        !quizStatResponse.quizStatDetails ||
        quizStatResponse.quizStatDetails.length === 0
      ) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        const quizObject = contentDetails;

        if (quizObject.RefContentType.name === "quiz_singleAnswer") {
          setQuizData(quizObject);

          if (quizObject.contentDetails.MasterImage?.name) {
            setThumbnailURL(
              `${API_ENDPOINTS.GET_IMAGE}/${quizObject.contentDetails.MasterImage.name}`
            );
          }

          setMinWords(quizObject.contentDetails.minWords || 0);
          setMaxWords(quizObject.contentDetails.maxWords || 200);
        }
      }
    }
  }, [quizStatResponse, contentDetails]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };
  // Handle quiz start
  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const calculateNextLessonSlug = (lessonData) => {
    for (const chapter of lessonData) {
      const lessonIndex = chapter.lessons.findIndex(
        (lesson) => lesson.lesson_slug === currentLessonSlug
      );
      if (lessonIndex !== -1) {
        if (lessonIndex < chapter.lessons.length - 1) {
          const nextLesson = chapter.lessons[lessonIndex + 1];
          if (!nextLesson.isLocked) {
            setNextLessonSlug(nextLesson.lesson_slug);
          } else {
            setNextLessonSlug("");
          }
        } else {
          const nextChapterIndex =
            lessonData.findIndex(
              (chap) => chap.chapter_id === chapter.chapter_id
            ) + 1;
          if (nextChapterIndex < lessonData.length) {
            const nextChapter = lessonData[nextChapterIndex];
            const nextLesson = nextChapter.lessons[0];
            if (!nextLesson.isLocked) {
              setNextLessonSlug(nextLesson.lesson_slug);
            } else {
              setNextLessonSlug("");
            }
          } else {
            setNextLessonSlug("");
          }
        }
        return;
      }
    }
    setNextLessonSlug("");
  };

  const handleTextChange = (event) => {
    const inputText = event.target.value;
    if (inputText && /^[\W_]/.test(inputText.trim()[0])) {
      // If the first character is a special character, reset the text
      notifyUser(
        "Invalid input",
        "Text should not start with a special character like a dot.",
        "warning"
      );
      return;  // Prevent setting the text if it starts with a special character
    }
    const wordArray = inputText.trim().split(/\s+/);
    const wordCount = wordArray.filter((word) => word.length > 0).length;

    if (wordCount <= maxWords) {
      setText(inputText);
      setIsTextEntered(true); // Set to true when user starts typing
    }
  };

  const wordCount = text
    .trim()
    .split(/\s+/)
    .filter((word) => word.length > 0).length;

  const handleNextClick = async () => {
    setIsSaving(true);
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    const payload = {
      course_id: lessonIdentifiers?.course_id || "",
      quiz_id: quizData?.contentDetails?.quiz_sa_id || "", // Assuming `quiz_id` is available in contentDetails
      answer_text: text, // The user's answer text
      completion_time:timeTakenInMinutes,
    };

    if (wordCount < minWords) {
      setIsSaving(false);
      notifyUser(
        "Minimum words",
        "Doesn't meet minimum words requirement",
        "warning"
      );
      return;
    }

    if (wordCount) {
      try {
        await saveQuixResponse(payload);
        setQuizSubmitted(true);
        updateLessonCompletionData(content);
        // navigate(`/app/course/${currentChapterSlug}/learn/${nextLessonSlug}`);
      } catch (error) {
        console.error("Error saving quiz response:", error);
      }
    } else {
      alert("The next lesson is locked or does not exist!");
    }
  };

  useEffect(() => {
    if (!isQuizStatDetailsNull || !isQuizFeedBackStatNull) {
      handleDisableFullScreen(content);
    }
  },[isQuizStatDetailsNull, isQuizFeedBackStatNull]);

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(
        contentDetails.RefContentType.name,
        contentDetails.contentDetails.quiz_sa_id,
        lessonIdentifiers?.course_id
      );
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setQuizData(null);
        onQuizSubmit();
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  useEffect(() => {
    if (quizStatApiResponse) {
      // console.log("quizStatDetails---> ", quizStatApiResponse);
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_sa_id === detail.response_sa_id
        );
        return {
          id: detail.response_sa_id,
          question: detail.MasterQuizSingleAnswer.MasterQuestion.question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on: null,
          completion_time: detail.completion_time,
          question_score: detail.MasterQuizSingleAnswer.MasterQuestion.score,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
        };
      });
      setModalData(combinedData);
      // console.log("combinedData---> ", combinedData);
    }
  }, [quizStatApiResponse]);

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  const handleTimeUp = async () => {
    setText("");
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
      data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizSingleAnswer
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        status="Submitted"
      />
    );
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return (
      <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizSingleAnswer
            .quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
    );
  }

  return quizData ? (
    <>
      <Box className="question-wrapper">
        {!quizStarted && (
          <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
        )}

        {quizStarted && (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              sx={{
                display: "flex",
                alignItems: "end",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={thumbnailURL || ""}
                alt="quiz thumbnail"
                className="question-wrapper__image"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={8}>
              {quizData.contentDetails.estimated_completion_time && (
                <QuizTimer
                  key={timerKey}
                  estimatedCompletionTime={
                    quizData.contentDetails.estimated_completion_time
                  }
                  handleComplete={handleTimeUp}
                />
              )}
              <Box className="question-wrapper__content">
                <Box className="question-wrapper__content-question">
                  <Typography className="question-wrapper__content-question-text" style={{ userSelect: "none" }}>
                    {quizData.contentDetails.MasterQuestion.question}{" "}
                  </Typography>
                </Box>
                <Box className="question-wrapper__content-input">
                  <TextField
                    size="medium"
                    className="question-wrapper__content-input-field"
                    variant="outlined"
                    multiline
                    rows={8}
                    value={text}
                    onChange={handleTextChange}
                    fullWidth
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        fontFamily: '"Gilroy Medium", sans-serif',
                      },
                    }}
                    onCopy={(e) => {
                      if (isProduction) {
                        e.preventDefault();
                        notifyUser(
                          "No copying allowed",
                          "You cannot copy the question text",
                          "warning"
                        );
                      }
                    }}
                    onPaste={(e) => {
                      if (isProduction) {
                        e.preventDefault();
                        notifyUser(
                          "No pasting allowed",
                          "You must type the answer yourself",
                          "warning"
                        );
                      }
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: "block", textAlign: "left", mt: 1 }}
                  >
                    {wordCount}/{minWords} Words
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ width: "95%" }}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  sx={{ marginRight: "1rem" }}
                >
                  <Button
                    id="next"
                    className="navigate-chapter__button"
                    variant="outlined"
                    onClick={handleNextClick}
                    disabled={!isTextEntered} // Disable if no text is entered or no next lesson
                  >
                     {isSaving ? 'Saving...' : 'Save'}
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  ) : (
    <p></p>
  );
};

export default QuestionAnswer;
