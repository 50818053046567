import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import QuizFeedback from "./QuizFeedback";
import LessonContext from "context/lessonContext/LessonContext";
import { API_ENDPOINTS, BASE_URL, PRODUCTION_URL } from "config/ApiConfig";
import usePostRequest from "hooks/usePostRequest";
import useGetRequest from "hooks/useGetRequest";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizTimer from "./TimerCard";
import QuizResultCard from "./QuizResultCard";

const QuizBasedOnStory = ({ content }) => {
  const { notifyUser } = useContext(NofityUserContext);
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);

  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const [display, setLoading] = useState(false);
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [quizPayload, setQuizPayload] = useState([]);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const isProduction = BASE_URL === PRODUCTION_URL;

  const { postData: autoSaveQuizPayload } = usePostRequest(API_ENDPOINTS.POST_AUTO_SAVE_QUIZ);
  const { data: getAutoSaveResponse, getData: getAutoSaveQuizPayload } = useGetRequest(API_ENDPOINTS.GET_AUTO_SAVED_QUIZ_DATA);

  const { updateLessonCompletionData } = useContext(LessonContext);

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  useEffect(() => {
    setTimerKey(timerKey + 1);
  }, [quizStarted]);

  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: postStoryQuizAnswer,
  } = usePostRequest(API_ENDPOINTS.SAVE_STORY_QUIZ_PROGRESS);

  // Auto-save data retrieval effect
  useEffect(() => {
    if (getAutoSaveResponse && getAutoSaveResponse.response) {
      try {
        const newData = JSON.parse(getAutoSaveResponse.response);
        const updatedAnswers = [...answers];
        
        newData.forEach((item) => {
          const questionIndex = contentDetails.questions.findIndex(
            (question) => question.question_id === item.quiz_id
          );
          
          if (questionIndex !== -1) {
            updatedAnswers[questionIndex] = item.answer_text;
          }
        });
        
        setAnswers(updatedAnswers);
      } catch (error) {
        console.error("Error parsing auto-save response:", error);
      }
    }
  }, [getAutoSaveResponse, contentDetails]);

  // Fetch auto-save data on component mount
  useEffect(() => {
    let isFetched = false;
    const fetchAutoSaveQuizPayload = async () => {
      if (isFetched) return;
      
      try {
        await getAutoSaveQuizPayload(`${content}/${lessonIdentifiers?.course_id}`);
        isFetched = true;
      } catch (error) {
        console.error("Error fetching auto-save quiz payload:", error);
      }
    };

    if (content && lessonIdentifiers?.course_id) {
      fetchAutoSaveQuizPayload();
    }
  }, [content, lessonIdentifiers?.course_id]);

  // Auto-save function
  const autoSaveQuizResponse = async () => {
    if (!contentDetails || !lessonIdentifiers?.course_id) return;

    const answeredQuestionsPayload = answers.map((answer, index) => ({
      quiz_id: contentDetails.questions[index].question_id,
      answer_text: answer,
    }));

    try {
      await autoSaveQuizPayload({
        course_id: lessonIdentifiers.course_id,
        content_id: content,
        response: answeredQuestionsPayload,
      });
    } catch (error) {
      console.error("Auto-save error:", error);
    }
  };

  useEffect(() => {
    if (lessonInfo) {
      const foundContent = lessonInfo.find(item => item.content_id === content);

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
        const questionsCount = foundContent.contentDetails.questions.length;
        setAnswers(Array(questionsCount).fill(""));
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    if (contentDetails && contentDetails.questions && contentDetails.questions.length > 0) {
      const firstQuestion = contentDetails.questions[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.questions[0].quiz_st_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      // Check if quizfeedBackStat is null or empty
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      // Check if quizStatDetails is null or empty
      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true); 
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_st_id === detail.response_st_id
        );
  
        // Ensure MasterQuestion and StoryQuestion are defined
        const question = detail.Question ? detail.Question.question : '';
        const stQuestion = detail.StoryQuestion ? detail.StoryQuestion.question : '';
  
        return {
          id: detail.response_st_id,
          stQuestion: stQuestion,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: detail.Question ? detail.Question.score : 0,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
  
      setModalData(combinedData);
    }
  }, [quizStatApiResponse]);
  


  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };


  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails.questions[0].quiz_st_id, lessonIdentifiers?.course_id);
      const timer = setTimeout(() => {
        setLoading(false);
        setQuizSubmitted(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);
    
  // Ensure contentDetails is loaded
  if (!contentDetails) return <Typography></Typography>;

  // Extract the story and questions from contentDetails
  const story = contentDetails.storyQuestionDetails?.question || "";
  const questions = contentDetails.questions || [];

  const countWords = (text) => (text ? text.trim().split(/\s+/).filter((word) => word).length : 0);

  const wordCount = countWords(answers[activeQuestionIndex]);
  const minWords = questions[activeQuestionIndex]?.minWords || 0;


  const handleInputChange = (event) => {
    // Don't reset answers, just update the current one
    const regex = /^[^\w]/;
    const newAnswer = event.target.value;
  
    if (newAnswer && regex.test(newAnswer[0])) {
      notifyUser("Invalid input", "Answer cannot start with a special character", "warning");
      return;
    }
  
    const updatedAnswers = [...answers];
    updatedAnswers[activeQuestionIndex] = newAnswer;
    setAnswers(updatedAnswers);

    // Clear existing debounce timeout
    if (debounceTimeout) clearTimeout(debounceTimeout);

    // Set new debounce timeout for auto-save
    const newTimeout = setTimeout(() => {
      autoSaveQuizResponse();
    }, 2000);

    setDebounceTimeout(newTimeout);
  };

  const handleNextQuestion = async () => {
    const endTime = new Date(); 
    const timeTaken = (endTime - startTime) / 1000; 
    const timeTakenInMinutes = (timeTaken / 60).toFixed(2);
    const currentQuestion = questions[activeQuestionIndex];
    const minWords = currentQuestion.minWords || 0;

    // Check if the answer meets the minimum word requirement
    if (countWords(answers[activeQuestionIndex]) < minWords) {
      notifyUser(
        "Minimum words",
        "Doesn't meet minimum words requirement",
        "warning"
      );
      return;
    }

    setIsSaving(false);

    // Create the payLoad dynamically for the current question
    const payLoad = {
      course_id: lessonIdentifiers.course_id,
      story_question_id: contentDetails.storyQuestionDetails.question_id,
      quiz_st_id: currentQuestion.quiz_st_id,
      quiz_id: currentQuestion.question_id,
      answer_text: answers[activeQuestionIndex] || "", 
      completion_time: timeTakenInMinutes,
    };

    // Update the quizPayload array with the current question's payload
    setQuizPayload((prevPayload) => {
      const updatedPayload = [...prevPayload];

      // Check if this question's payload has already been added (in case user navigates back)
      const existingPayloadIndex = updatedPayload.findIndex(
        (p) => p.quiz_id === currentQuestion.question_id
      );

      if (existingPayloadIndex > -1) {
        // If already added, replace the old payload with the updated one
        updatedPayload[existingPayloadIndex] = payLoad;
      } else {
        // Otherwise, append the new payload to the array
        updatedPayload.push(payLoad);
      }

      return updatedPayload;
    });

    // If on the last question, submit the quiz
    if (activeQuestionIndex === questions.length - 1) {
      try {
        const finalPayload = await new Promise((resolve) =>
          setQuizPayload((prevPayload) => {
            resolve([...prevPayload]);
            return prevPayload;
          })
        );

        await postStoryQuizAnswer(finalPayload);
        setQuizSubmitted(true);
        updateLessonCompletionData(content);

      } catch (error) {
        console.error("Error submitting quiz:", error);
        notifyUser(
          "Submission Error",
          "There was an issue submitting your quiz. Please try again.",
          "error"
        );
      }
    } else {
      // Move to the next question
      setActiveQuestionIndex(activeQuestionIndex + 1);
    }
  };

  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };

  const handlePreviousQuestion = () => {
    if (activeQuestionIndex > 0) {
      setActiveQuestionIndex(activeQuestionIndex - 1);
    }
  };

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (questions.length === 0) {
    return <Typography></Typography>;
  }

  const handleTimeUp = async () => {
    setAnswers([]);
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
      data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizStory.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return  <QuizResultCard
        data={modalData}
        title={
          quizStatApiResponse.quizStatDetails[0].MasterQuizStory.quiz_name
        }
        date={quizStatApiResponse.quizStatDetails[0].submission_time}
        score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
        status="Graded"
      />
  }

  return display ? (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box className="AFQ_wrapper">
          {estimatedCompletionTime && (
            <Box className="AFQ_wrapper__time">
              <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp} />
            </Box>
          )}
          <Box
            className="AFQ_wrapper__long-question-wrapper"
            dangerouslySetInnerHTML={{
              __html: story.replace(/&nbsp;/g, ' '),
            }}
          />
         <Box 
            className="AFQ_wrapper__Question-box-v1" 
            sx={{ 
              width: '100%',  // Changed: Increased width to 100%
              padding: '24px', // Changed: Added padding
              boxSizing: 'border-box' // Changed: Added to maintain proper sizing with padding
            }}
          >
            <Box className="AFQ_wrapper__Question-box-v1--number">
              <Typography className="AFQ_wrapper__Question-box-v1--number-text">
                {`Question ${activeQuestionIndex + 1}/${questions.length}`}
              </Typography>
            </Box>
            <Typography className="AFQ_wrapper__Question-box-v1--question" style={{ userSelect: "none" }}>
              {questions[activeQuestionIndex].question}
            </Typography>
          </Box>
          <Box className="AFO_wrapper__answer-wrapper" sx={{ flex: 1, marginTop: "30px" }}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <TextField
                  className="input-field"
                  required
                  fullWidth
                  multiline
                  minRows={10}
                  value={answers[activeQuestionIndex]}
                  onChange={handleInputChange}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                  onCopy={(e) => {
                    if (isProduction) {
                      e.preventDefault();
                      notifyUser(
                        "No copying allowed",
                        "You cannot copy the question text",
                        "warning"
                      );
                    }
                  }}
                  onPaste={(e) => {
                    if (isProduction) {
                      e.preventDefault();
                      notifyUser(
                        "No pasting allowed",
                        "You must type the answer yourself",
                        "warning"
                      );
                    }
                  }}
                />
                <Typography variant="caption" color="textSecondary" sx={{ display: "block", textAlign: "left", mt: 1 }}>
                  {wordCount}/{minWords} Words
                </Typography>
              </Grid>
            </Grid>
            <Box className="SAQ_wrapper-navigate" sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                onClick={handlePreviousQuestion}
                disabled={activeQuestionIndex === 0} // Disable if on the first question
                sx={{ mr: 2 }}
              >
                Previous
              </Button>
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                onClick={handleNextQuestion}
                disabled={countWords(answers[activeQuestionIndex]) < minWords}
                sx={{ ml: questions.length > 1 ? 2 : 0 }}
              >
                {activeQuestionIndex === questions.length - 1 ? isSaving ? 'Saving...' : 'Save' : "Next"}
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>

  ) : (
    <></>
  );
};

export default QuizBasedOnStory;
