import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { useGoogleOneTapLogin } from "@react-oauth/google";
import { useFormik } from "formik";
import { loginFormSchema } from "config/schemas/formSchema";
import usePostRequest from "hooks/usePostRequest";
import useGetRequest from "hooks/useGetRequest"; // Import the useGetRequest hook
import { API_ENDPOINTS } from "config/ApiConfig";
import UserContext from "context/userContext/UserContext";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IconButton, InputAdornment, InputLabel } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import mtsLogo from "assets/images/mts-logo.png";
import { encryptData } from "utilityFunction/encryption&Decryption";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";

function Copyright(props) {
  const currentYear = new Date().getFullYear();

  return (
    <Typography
      variant="caption"
      align="center"
      className="footer-text"
      {...props}
    >
      {`Copyright ©${currentYear} `}
      <Link
        className="text my-true-skill"
        to="https://mytrueskills.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        My True Skills
      </Link>
    </Typography>
  );
}

export default function Login() {
  //navigate instance
  const navigate = useNavigate();

  //user context
  const { onLogin } = useContext(UserContext);

  //nofity user context
  const { notifyUser } = useContext(NofityUserContext);

  //state to store the password
  const [showPassword, setShowPassword] = useState(false);
  //function to handle the password toggle
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //destructure the data from the hook
  const {
    data: loginData,
    loading,
    error,
    postData,
  } = usePostRequest(API_ENDPOINTS.LOGIN_API);

  //post req for google one tap login api
  const {
    data: googleLoginData,
    loading: googleLoginLoading,
    error: googleLoginError,
    postData: googlePayload,
  } = usePostRequest(API_ENDPOINTS.LOGIN_USING_GOOGLE_API);

  const { getData: validateToken, data: tokenValidationData } = useGetRequest(API_ENDPOINTS.VALIDATE_TOKEN_API);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  //form submibssion function on click
  const onSubmit = async (values, actions) => {
    try {
      // Encrypt the email and password
      const encryptedEmail = encryptData(
        values.username.includes('@') ? values.username.trim().toLowerCase() : values.username.trim()
      );      
      const encryptedPassword = encryptData(values.password);
      //Send the encrypted payload with the request
      await postData({
        emailOrUsername: encryptedEmail,
        password: encryptedPassword,
      });
    } catch (err) {
      if (err.response) {
        // The server responded with a status other than 2xx
        notifyUser(
          "Login Failed",
          err.response.data.message || "An error occurred.",
          "error"
        );
      } else if (err.request) {
        // The request was made but no response was received
        notifyUser(
          "Network Error",
          "No response from the server. Please try again.",
          "warning"
        );
      } else {
        // An error occurred in setting up the request
        notifyUser("Unexpected Error", err.message, "error");
      }
    }

    //reset the form after submitting
    if (!loading) {
      actions.resetForm();
    }
  };

  //useeffect to run the toast if there is an error
  useEffect(() => {
    if (!loading) {
      if (error) {
        notifyUser(
          "Incorrect Username or Password",
          "The enter value is not correct",
          "error"
        );
      }
    }
  }, [error]);

  useEffect(() => {
    if (loginData) {
      // Store user data in localStorage
      localStorage.setItem("user_id", JSON.stringify(loginData.user_id));
      localStorage.setItem("token", JSON.stringify(loginData.token));
      onLogin(loginData.user_id);
      // Check if there's an intended URL stored in sessionStorage
      const intendedUrl = sessionStorage.getItem('intendedUrl');
      if (intendedUrl) {
        // Redirect to the intended course page
        window.location.href = intendedUrl;
        // Clear the intended URL from sessionStorage
        sessionStorage.removeItem('intendedUrl');
      } else {
        // Otherwise, redirect to the dashboard
        window.location.href = "/app/dashboard";
      }
  
      // console.log("API integration success, logged in");
    }
  }, [loginData]);
  

  // direct login using token start 
  const checkTokenAndLogin = async () => {
    const token = localStorage.getItem("token");
    
    if (!token) return;

    try {
      // Validate the token using the validateToken method
      await validateToken({ token });

      if (tokenValidationData?.valid) {
        const { user_id, token: newToken, user } = tokenValidationData;

        if (user_id && newToken) {
          // Store the new token and user_id in local storage
          localStorage.setItem("user_id", user_id);
          localStorage.setItem("token", newToken);
          onLogin(user);
          window.location.href = "/app/dashboard";
        } else {
          throw new Error("Invalid token data");
        }
      } else {
        throw new Error("Invalid token");
      }
    } catch (err) {
      console.error("Error logging in with token:", err);
      localStorage.removeItem("token");
    }
  };

  useEffect(() => {
    checkTokenAndLogin();
  }, []);
  // direct login using token end 


  const {
    errors,
    handleBlur,
    touched,
    handleChange,
    values,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    //local state
    initialValues: {
      username: "",
      password: "",
    },

    validate: (values) => {
      const errors = {};
      if (!values.username) {
        errors.username = "Username or Email is Required";
      }
      return errors;
    },

    //validate the email and password( client side validation)
    validationSchema: loginFormSchema,
    //onSubmit handler
    onSubmit,
  });
  //for google one tap login
  const oneTapLogin = useGoogleOneTapLogin({
    onSuccess: async (credentialResponse) => {
      // console.log(credentialResponse);
      // Destructuring
      const { credential } = credentialResponse;
      if (credential) {
        try {
          await googlePayload({ tokenId: credential });
        } catch (error) {
          console.error("Error sending Google credential to server:", error);
          notifyUser(
            "Google Login Error",
            "An error occurred while connecting with Google. Please try again.",
            "error"
          );
        }
      } else {
        console.error("No credential received from Google login");
        notifyUser(
          "Google Login Failed",
          "No credentials received from Google. Please try again.",
          "warning"
        );
      }
    },
    onError: (error) => {
      // console.log("Google One Tap Login Error:", error);
      notifyUser(
        "Google Login Error",
        "An error occurred while logging in with Google. Please try again.",
        "error"
      );
    },
  });

  // Use an effect to handle the response after loading is complete
  useEffect(() => {
    if (!googleLoginLoading && googleLoginData) {
      if (googleLoginData.user_id && googleLoginData.token) {
        // Store the user ID and token in local storage
        localStorage.setItem("user_id", googleLoginData.user_id);
        localStorage.setItem("token", googleLoginData.token);

        // console.log(
        //   "Google login successful. User data stored in local storage."
        // );
        notifyUser(
          "Login Successful",
          "You have successfully logged in with Google.",
          "success"
        );
        // navigate("/app/dashboard");
        window.location.href = "/app/dashboard";
      } else {
        console.error("Received incomplete data from Google login API");
      }
    } else if (googleLoginError) {
      console.error("Error during Google login:", googleLoginError);
      notifyUser(
        "Account Not Found",
        "Error while logging in with google",
        "error"
      );
      navigate("/create-account");
    }
  }, [googleLoginLoading, googleLoginData]);

  return (
    <Box className="auth-form-wrapper">
      <Box className="auth-form-card" id="login">
        <Box className="login-form">
          <img src={mtsLogo} alt="logo" className="login-card-logo" />
          <Typography component="h1" variant="h5" className=" text header-text">
            Log in
          </Typography>
          <Typography variant="h6" className="text header-caption">
            Welcome back! Please enter your details.
          </Typography>
          <Box mt={2} component="form" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InputLabel className=" text input-field-label input-field-label-auth">
                  Email/Username
                </InputLabel>
                <TextField
                  autoFocus
                  size="small"
                  className="input-field"
                  required
                  fullWidth
                  id="username"
                  name="username"
                  placeholder="Eg : VF4D6TG4R"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="username"
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                  }}
                />
                {errors.username && touched.username ? (
                  <Typography className="error-input_login">
                    {errors.username}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12}>
                <InputLabel className="text input-field-label input-field-label-auth">
                  Password
                </InputLabel>
                <TextField
                  placeholder="••••••••"
                  size="small"
                  className="input-field"
                  required
                  fullWidth
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="password"
                  autoComplete="new-password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                      color: "#667085",
                      fontFamily: "Gilroy-Regular, sans-serif",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityOff sx={{ mr: 1, width: "60%" }} />
                          ) : (
                            <Visibility sx={{ mr: 1, width: "60%" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && touched.password ? (
                  <Typography className="error-input_login">
                    {errors.password}
                  </Typography>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item mt={3} mb={2} className="text">
                <Link
                  to="/forgot-password"
                  variant="body2"
                  className=" text forgot-password"
                >
                  Forgot Password ?
                </Link>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              disabled={isSubmitting}
              variant="contained"
              className="button button-signup"
            >
              Sign in
            </Button>
            <Grid container justifyContent="center" mt={2} mb={2}>
              <Grid item>
                <Typography className="text create-account">
                  Don't have an account?{" "}
                  <Link to="/create-account">Create Account</Link>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Copyright />
    </Box>
  );
}