import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { API_ENDPOINTS, BASE_URL, PRODUCTION_URL } from "config/ApiConfig";
import LessonContext from "context/lessonContext/LessonContext";
import usePostRequest from "hooks/usePostRequest";
import React, { useContext, useState, useEffect, useCallback } from "react";
import NofityUserContext from "context/notifyUserContext/NotifyUserContext";
import QuizFeedback from "./QuizFeedback";
import QuizTimer from "./TimerCard";
import QuizTimerStartOverlay from "overlay/quizComponentOverlay";
import QuizResultCard from "./QuizResultCard";
import useGetRequest from "hooks/useGetRequest";

const MultipleLongAnswerQuiz = ({ content }) => {
  const { message, notifyUser, clearUserNotification } =
    useContext(NofityUserContext);
  const { lessonInfo, lessonIdentifiers } = useContext(LessonContext);
  const [quizSubmitted, setQuizSubmitted] = useState(false);
  const course_id = lessonIdentifiers.course_id;
  const [contentDetails, setContentDetails] = useState(null);
  const [contentDetails1, setContentDetails1] = useState(null);
  const [quizStarted, setQuizStarted] = useState(false);
  const [timerKey, setTimerKey] = useState(0);
  const [estimatedCompletionTime, setEstimatedCompletionTime] = useState(null);
  const [quizStatData, setQuizStatData] = useState(false);
  const [quizStatApiResponse, setQuizStatApiResponse] = useState(null);
  const [isQuizFeedBackStatNull, setIsQuizFeedBackStatNull] = useState(false);
  const [isQuizStatDetailsNull, setIsQuizStatDetailsNull] = useState(false);
  const [display, setLoading] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const { updateLessonCompletionData } = useContext(LessonContext);

  const isProduction = BASE_URL === PRODUCTION_URL;

  const { data: quizStatResponse, postData: quizStatPayload } =
    usePostRequest(API_ENDPOINTS.FETCH_QUIZ_STAT);

  const { postData: autoSaveQuizPayload } =
    usePostRequest(API_ENDPOINTS.POST_AUTO_SAVE_QUIZ);

  const { data: getAutoSaveResponse, getData: getAutoSaveQuizPayload } =
    useGetRequest(API_ENDPOINTS.GET_AUTO_SAVED_QUIZ_DATA);

  useEffect(() => {
    setTimerKey(timerKey + 1);
  }, [quizStarted]);

  useEffect(() => {
    if (lessonInfo) {
      const contentArray = lessonInfo || [];
      const foundContent = contentArray.find(
        (item) => item.content_id === content
      );

      if (foundContent) {
        setContentDetails(foundContent.contentDetails);
        setContentDetails1(foundContent);
      }
    }
  }, [lessonInfo, content]);

  useEffect(() => {
    let isFetched = false; // Flag to ensure the API is called only once

    const fetchAutoSaveQuizPayload = async () => {
      if (isFetched) return; // Prevent multiple calls

      try {
        await getAutoSaveQuizPayload(`${content}/${lessonIdentifiers?.course_id}`);
        isFetched = true; // Mark as fetched after a successful call
      } catch (error) {
        console.error("Error fetching auto-save quiz payload:", error);
      }
    };

    if (content && lessonIdentifiers?.course_id) {
      fetchAutoSaveQuizPayload();
    }
  }, [content, lessonIdentifiers?.course_id]); // Dependencies to trigger the effect only when required values are available



  useEffect(() => {
    if (contentDetails && contentDetails.length > 0) {
      const firstQuestion = contentDetails[0];
      setEstimatedCompletionTime(firstQuestion.estimated_completion_time);
    }
  }, [contentDetails]);

  useEffect(() => {
    if (contentDetails1 && !quizStatData && lessonIdentifiers?.course_id) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id)
      setQuizStatData(true);
    }
  }, [contentDetails1, lessonIdentifiers, quizStatPayload]);

  useEffect(() => {
    if (quizStatResponse && contentDetails1) {
      setQuizStatApiResponse(quizStatResponse);
      if (!quizStatResponse.quizfeedBackStat || quizStatResponse.quizfeedBackStat.length === 0) {
        setIsQuizFeedBackStatNull(true);
        setIsQuizStatDetailsNull(false);
      } else {
        setIsQuizFeedBackStatNull(false);
        setIsQuizStatDetailsNull(true);
      }

      if (!quizStatResponse.quizStatDetails || quizStatResponse.quizStatDetails.length === 0) {
        setIsQuizStatDetailsNull(true);
        setIsQuizFeedBackStatNull(true);
        setLoading(true);
      }
    }
  }, [quizStatResponse, contentDetails1]);

  useEffect(() => {
    if (quizStatApiResponse) {
      const combinedData = quizStatApiResponse.quizStatDetails.map((detail) => {
        const feedback = quizStatApiResponse.quizfeedBackStat.find(
          (fb) => fb.feedback.response_la_id === detail.response_la_id
        );

        const question = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.question
          : null;
        const questionScore = detail.MasterQuestion && detail.MasterQuestion
          ? detail.MasterQuestion.score
          : null;

        return {
          id: detail.response_la_id,
          question: question,
          answer: detail.answer_text,
          submission_time: detail.submission_time,
          feedback_submission_time: feedback ? feedback.feedback.updated_on : null,
          question_score: questionScore,
          feedback: feedback ? feedback.feedback.feedback_text : null,
          feedback_score: feedback ? feedback.feedback.score : null,
          completion_time: detail.completion_time,
        };
      });
      setModalData(combinedData);
    }
  }, [quizStatApiResponse]);

  const getQuizStatData = async (ref_content_type, quiz_id, course_id) => {
    const payLoad = {
      course_id: course_id,
      quiz__id: quiz_id,
      ref_content_type: ref_content_type,
    };

    try {
      await quizStatPayload(payLoad);
    } catch (error) {
      console.error("Error saving quiz response:", error);
    }
  };

  
  
  const {
    data: createOrderData,
    error: createOrderError,
    loading: loadingCreateOrder,
    postData: postLongQuizAnswer,
  } = usePostRequest(API_ENDPOINTS.SAVE_LONG_ANSWER_QUIZ);

  const questions =
    contentDetails?.map((detail) => ({
      question: detail.question,
      minWords: detail.minWords,
      maxWords: detail.maxWords,
      total_score: detail.total_score,
      question_id: detail.question_id,
      quiz_la_id: detail.quiz_la_id,
    })) || [];

  const [activeQuestionIndex, setActiveQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(() => {
    // Create an array of empty strings with length equal to number of questions
    return Array(questions.length || 0).fill("");
  });
  const [progress, setProgress] = useState(
    questions.length > 0
      ? ((activeQuestionIndex + 1) / questions.length) * 100
      : 0
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const countWords = (text) => {
    return (text || "")
      .trim()
      .split(/\s+/)
      .filter((word) => word).length;
  };

  const wordCount = countWords(answers[activeQuestionIndex]);
  const minWords = questions[activeQuestionIndex]?.minWords || 0;

  const autoSaveQuizResponse = async () => {
    // Prepare the payload for all answered questions up to the current one
    const answeredQuestionsPayload = [];
    for (let i = 0; i <= activeQuestionIndex; i++) {
      answeredQuestionsPayload.push({
        quiz_id: questions[i].question_id,
        answer_text: answers[i],
      });
    }

    // Call autoSaveQuizPayload API with all answered questions
    await autoSaveQuizPayload({
      course_id: course_id,
      content_id: content,
      response: answeredQuestionsPayload,
    });
  }
  const [debounceTimeout, setDebounceTimeout] = useState(null); // saving the data 

  const handleInputChange = (event) => {
    if (!isSubmitted) {
      const regex = /^[^\w]/;
      const newAnswer = event.target.value;

      if (newAnswer && regex.test(newAnswer[0])) {
        notifyUser("Invalid input", "Answer cannot start with a special character", "warning");
        return;
      }

      // Update only the current question's answer
      setAnswers(prevAnswers => {
        const newAnswers = [...prevAnswers];
        newAnswers[activeQuestionIndex] = newAnswer;
        return newAnswers;
      });

      // Clear any existing debounce timeout
      if (debounceTimeout) clearTimeout(debounceTimeout);

      // Set a new debounce timeout to call the API after 5 seconds
      const newTimeout = setTimeout(() => {
        autoSaveQuizResponse();
      }, 2000);

      setDebounceTimeout(newTimeout);
    }
  };

  useEffect(() => {
    const fetchAutoSaveData = () => {
      if (getAutoSaveResponse) {
        // console.log("getAutoSaveResponse ---> ", getAutoSaveResponse);

        // Check if the response is not empty and is a valid string
        if (getAutoSaveResponse.response) {
          try {
            // Parse the response data (assuming it's a stringified array)
            const newData = JSON.parse(getAutoSaveResponse.response);

            // Populate the answers array by comparing quiz_id
            const updatedAnswers = [...answers]; // Copy current answers

            newData.forEach((item) => {
              // Compare quiz_id and update the answers array if needed
              const questionIndex = questions.findIndex(
                (question) => question.question_id === item.quiz_id
              );

              if (questionIndex !== -1) {
                updatedAnswers[questionIndex] = item.answer_text;
              }
            });

            // Update the answers state
            setAnswers(updatedAnswers);

          } catch (error) {
            console.error("Error parsing the response:", error);
          }
        } else {
          console.warn("Empty or invalid response data.");
        }
      }
    };

    fetchAutoSaveData();
  }, [getAutoSaveResponse, questions.length]); // Ensure dependencies are correct


  const handleStartQuiz = () => {
    setQuizStarted(true);
    setStartTime(new Date());
  };


  const handleNextQuestion = async () => {
    if (isSubmitted) return;

    if (countWords(answers[activeQuestionIndex]) < minWords) {
      notifyUser(
        "Minimum words",
        "Doesn't meet minimum words requirement",
        "warning"
      );
      return;
    }

    if (activeQuestionIndex < questions.length - 1) {
      const newIndex = activeQuestionIndex + 1;
      setActiveQuestionIndex(newIndex);
      setProgress(((newIndex + 1) / questions.length) * 100);
    } else {
      setIsSaving(true);
      const endTime = new Date();
      const timeTaken = (endTime - startTime) / 1000;
      const timeTakenInMinutes = (timeTaken / 60).toFixed(2);

      try {
        for (let i = 0; i < questions.length; i++) {
          const payload = {
            course_id: course_id,
            quiz_id: questions[i].question_id,
            answer_text: answers[i],
            quiz_la_id: questions[i].quiz_la_id,
            completion_time: timeTakenInMinutes,
          };
          await postLongQuizAnswer(payload);
        }
        setQuizSubmitted(true);
        updateLessonCompletionData(content);
        setIsSubmitted(true);
      } catch (error) {
        console.error("Error submitting answers:", error);
      }
    }
  };

  // Update answers initialization when questions change
  useEffect(() => {
    if (questions.length > 0) {
      setAnswers(prev => {
        // Preserve existing answers if they exist, otherwise use empty strings
        return Array(questions.length).fill("").map((_, index) => prev[index] || "");
      });
    }
  }, [questions.length]);

  const handlePreviousQuestion = () => {
    if (!isSubmitted && activeQuestionIndex > 0) {
      const newIndex = activeQuestionIndex - 1;
      setActiveQuestionIndex(newIndex);
      setProgress(((newIndex + 1) / questions.length) * 100);
    }
  };

  useEffect(() => {
    if (quizSubmitted) {
      getQuizStatData(contentDetails1.RefContentType.name, contentDetails1.contentDetails[0].quiz_la_id, lessonIdentifiers?.course_id);
      const timer = setTimeout(() => {
        setQuizSubmitted(false);
        setLoading(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [quizSubmitted]);

  if (quizSubmitted) {
    return <QuizFeedback success={true} />;
  }

  if (questions.length === 0) {
    return <Typography></Typography>;
  }





  const handleTimeUp = async () => {
    setAnswers("");
  };

  if (!isQuizStatDetailsNull && isQuizFeedBackStatNull) {
    return <QuizResultCard
      data={modalData}
      title={quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer.quiz_name}
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      status="Submitted"
    />
  }

  if (isQuizStatDetailsNull && !isQuizFeedBackStatNull) {
    return <QuizResultCard
      data={modalData}
      title={
        quizStatApiResponse.quizStatDetails[0].MasterQuizLongAnswer
          .quiz_name
      }
      date={quizStatApiResponse.quizStatDetails[0].submission_time}
      score={quizStatApiResponse.quizfeedBackStat[0].feedback.score}
      status="Graded"
    />
  }

  return display && (
    <>
      {!quizStarted && (
        <QuizTimerStartOverlay handleStartQuiz={handleStartQuiz} />
      )}
      {quizStarted && (
        <Box className="SAQ_wrapper">
          <Box className="SAQ_wrapper__Question-box">
            <Box className="SAQ_wrapper__Question-box--number">
              <Typography className="SAQ_wrapper__Question-box--number-text">
                {`Question ${activeQuestionIndex + 1}/${questions.length}`}
              </Typography>
            </Box>
            <Typography className="SAQ_wrapper__Question-box--question" style={{ userSelect: "none" }}>
              {questions[activeQuestionIndex].question}
            </Typography>
          </Box>
          <Box className="SAQ_wrapper__answer-wrapper" sx={{ flex: 1 }}>
            <Box className="SAQ_wrapper__options-wrapper">
              {estimatedCompletionTime && (
                <QuizTimer key={timerKey} estimatedCompletionTime={estimatedCompletionTime} handleComplete={handleTimeUp} />
              )}
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <TextField
                    className="input-field"
                    required
                    fullWidth
                    multiline
                    minRows={10}
                    value={answers[activeQuestionIndex]}
                    onChange={handleInputChange}
                    name={`answer-${activeQuestionIndex}`}
                    id={`answer-${activeQuestionIndex}`}
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                        color: "#667085",
                        fontFamily: "Gilroy-Regular, sans-serif",
                        overflowY: "hidden" // This removes the vertical scrollbar
                      },
                      readOnly: isSubmitted,
                    }}
                    onCopy={(e) => {
                      if (isProduction) {
                        e.preventDefault();
                        notifyUser(
                          "No copying allowed",
                          "You cannot copy the question text",
                          "warning"
                        );
                      }
                    }}
                    onPaste={(e) => {
                      if (isProduction) {
                        e.preventDefault();
                        notifyUser(
                          "No pasting allowed",
                          "You must type the answer yourself",
                          "warning"
                        );
                      }
                    }}
                  />
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    sx={{ display: "block", textAlign: "left", mt: 1 }}
                  >
                    {wordCount}/{minWords} Words
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <Box
              className="SAQ_wrapper-navigate"
              id="no-bg"
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {questions.length > 1 && (
                <Button
                  className="SAQ_wrapper-navigate__button"
                  variant="outlined"
                  id="white-bg"
                  onClick={handlePreviousQuestion}
                  disabled={activeQuestionIndex === 0 || isSubmitted}
                >
                  Previous
                </Button>
              )}
              <Button
                className="SAQ_wrapper-navigate__button"
                variant="outlined"
                id="white-bg"
                onClick={handleNextQuestion}
                disabled={
                  countWords(answers[activeQuestionIndex]) < minWords ||
                  isSubmitted
                }
                sx={{ ml: questions.length > 1 ? 2 : 0 }}
              >
                {activeQuestionIndex === questions.length - 1 ? isSaving ? 'Saving...' : 'Save' : "Next"}
              </Button>
            </Box>
          </Box>
          <Box>
            <LinearProgress
              className="SAQ_wrapper__progress"
              variant="determinate"
              value={progress}
            />
          </Box>
        </Box>
      )}
    </>
  );
};

export default MultipleLongAnswerQuiz;