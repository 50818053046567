import React from 'react';
import { Box, IconButton } from "@mui/material";
import { FaThumbtack, FaBookOpen } from "react-icons/fa";

const ContextMenu = ({ contextMenu, onClose, onPin, onDictionary }) => {
  if (!contextMenu) return null;

  return (
    <Box
      sx={{
        position: "absolute",
        top: contextMenu.mouseY,
        left: contextMenu.mouseX,
        backgroundColor: "#fff",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
        zIndex: 1000,
        padding: "8px",
        display: "flex",
        alignItems: "center",
        gap: "8px",
      }}
      onMouseLeave={onClose}
    >
      <IconButton onClick={onPin} size="small">
        <FaThumbtack />
      </IconButton>
      <IconButton onClick={onDictionary} size="small">
        <FaBookOpen />
      </IconButton>
    </Box>
  );
};

export default ContextMenu;